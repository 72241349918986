// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-aqua-park-tsx": () => import("/opt/build/repo/src/pages/aqua-park.tsx" /* webpackChunkName: "component---src-pages-aqua-park-tsx" */),
  "component---src-pages-imprint-tsx": () => import("/opt/build/repo/src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("/opt/build/repo/src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-references-tsx": () => import("/opt/build/repo/src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-success-tsx": () => import("/opt/build/repo/src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-surf-park-tsx": () => import("/opt/build/repo/src/pages/surf-park.tsx" /* webpackChunkName: "component---src-pages-surf-park-tsx" */),
  "component---src-pages-terms-tsx": () => import("/opt/build/repo/src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-wake-park-tsx": () => import("/opt/build/repo/src/pages/wake-park.tsx" /* webpackChunkName: "component---src-pages-wake-park-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

